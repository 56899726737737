import React from "react"
import Layout from "../components/layout"
import { container, section } from "../styles/schedule.module.css"

const SchedulePage = () => {
  return (
    <Layout pageTitle={"Schedule | Eames Maloney"}>
      <container className={container}>

        <container className={section}>
          <h1>The Leeds Library</h1>
          <p>3.30pm - Arrival</p>
          <p>4pm - Ceremony</p>
          <p><i>Unfortunately the ceremony at The Leeds Library is only for family and close family friends, due to limited capacity.</i></p>
        </container>

        <container className={section}>
          <h1>The Faversham</h1>
          <p>5pm - Drinky Drinks</p>
          <p>6.30pm - Diney Dine</p>
          <p>8.30pm - Dancey Dance</p>
          <p>1am - Bed</p>
        </container>

      </container>
    </Layout>
  )
}

export default SchedulePage
